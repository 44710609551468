<template>
  <div class="login-screen sys-screen">
    <div class="center-block">
      <notifications width="470" />
      <div
        id="login-container"
        class="positioning fadeInDown animated"
        :class="{'positioning-gdpr' : gdprToAccept}"
      >
        <div class="vertical-10p">
          <img
            src="../../../public/static/assets/img/logo.svg"
            class="center-block logo logo-login"
            alt="Zordon logo"
          >
          <div
            v-if="!gdprToAccept"
            class="text-center"
          >
            <div class="text-center">
              <form @submit.prevent="onSubmitForm()">
                <div class="form-group has-feedback">
                  <input
                    v-model="username"
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    name="username"
                    autocomplete="username"
                  >
                </div>
                <div class="form-group has-feedback">
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    :placeholder="$t('login.password')"
                    name="password"
                    autocomplete="password"
                  >
                </div>
                <button
                  type="submit"
                  :class="'zord-btn btn zord-btn-primary btn-primary btn-flat zord-btn-flat ' + (isLoading ? 'disabled' : '')"
                  :disabled="isLoading"
                >
                  {{$t('login.loginButton')}}
                </button>
              </form>
              <hr class="line-login">
              <router-link :to="{name: 'security_password_reset_email'}">
                {{$t('login.forgotPassword')}}
              </router-link>
            </div>
          </div>
          <div v-if="gdprToAccept">
            <p
              class="text-center gdpr-text"
              @scroll="handleMouseScroll"
              @mouseenter="handleMouseScroll"
            >
              <gdpr-content :content="gdprContent" />
            </p>
            <div style="display: flex; flex-direction: row">
              <button
                class="zord-btn btn zord-btn-primary btn-primary btn-flat zord-btn-flat"
                @click="declineGDPR"
              >
                {{$t('login.gdprRefuse')}}
              </button>
              <button
                v-if="!acceptButtonEnabled"
                class="zord-btn btn zord-btn-primary btn-primary btn-flat zord-btn-flat disabled"
              >
                {{$t('login.gdprAccept')}}
              </button>
              <button
                v-else
                class="zord-btn btn zord-btn-primary btn-primary btn-flat zord-btn-flat"
                @click="confirmGDPR"
              >
                {{$t('login.gdprAccept')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Authenticate from './Authenticate'
import GdprContent from './GdprContent'

export default {
  components: { GdprContent },
  mixins: [
    Authenticate
  ],
  data () {
    return {
      acceptButtonEnabled: false,
      username: '',
      password: '',
      status: {
        active: 1,
        confirmed: 2
      }
    }
  },
  mounted () {
    if (this.$route.params.hasOwnProperty('notification') && this.$route.params.notification === 'security-logout') {
      this.$notify({
        type: 'info',
        text: this.$t('login.autoLogout'),
        duration: -1
      })
    }
  },
  methods: {
    handleMouseScroll (event) {
      if (Math.floor((event.target.clientHeight + event.target.scrollTop) / 100) === Math.floor(event.target.scrollHeight / 100)) {
        this.acceptButtonEnabled = true
      } else {
        this.acceptButtonEnabled = false
      }
      // if (event.target.clientHeight + event.target.offsetTop)
    },
    onSubmitForm () {
      const { username, password } = this
      this.checkLogin(username, password)
    },
    confirmGDPR () {
      const { username, password } = this
      this.checkLogin(username, password, 'gdprAccepted')
    },
    declineGDPR () {
      this.gdprToAccept = false
    }
  }
}
</script>

<style scoped>
html {
    position: relative;
    min-height: 100%;
}
form {
    padding: 20px;
}
.positioning-gdpr{
  margin: auto;
  width: 50%;
}
.positioning .logo-login {
  height: 10%;
}
.positioning-gdpr .logo-login {
  height: 9rem;
}
.gdpr-text::-webkit-scrollbar {
  width: 6px;
}
.gdpr-text {
  scrollbar-width: thin;
  scrollbar-color: #3779af;
}
.gdpr-text::-webkit-scrollbar-track {
  background: black;
}
.gdpr-text::-webkit-scrollbar-thumb {
  background: #3779af;
}
.gdpr-text::-webkit-scrollbar-thumb:hover {
  background: #3779af;
}

.gdpr-text {
  overflow: auto;
  max-height: 40rem;
  }
</style>
