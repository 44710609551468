<script>
import Vue from 'vue'
export default {
  name: 'GdprContent',
  functional: true,
  props: {
    content: {}
  },
  render: function (createElement, context) {
    let text = context.props.content.split('').filter(char => !char.includes('↑')).join('')
    return createElement(Vue.compile(text))
  }
}
</script>
